<template>
    <div class="content">
        <div class="tool">
            <el-button type="primary" class="el-icon-plus" @click="AddClick"> 新增</el-button>
            <el-button type="primary" class="el-icon-check" @click="DelList"> 批量解除</el-button>
            <el-button type="primary" class="el-icon-bottom" @click="AllDown"> 全部下发</el-button>
        </div>
        <el-table :data='$store.state.BlackDataList' border stripe :highlight-current-row='true' max-height='calc(100vh - 140px)' height='calc(100vh - 140px)' ref='bkTable'>
            <el-table-column align="center" type="selection" width="50"></el-table-column>
            <el-table-column align="center" prop="bk_plateno" label="车牌号"></el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" class="el-icon-check" @click="Del(scope.row)"> 解除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog v-if="dialogVisible" title="黑名单" :visible.sync="dialogVisible" width='400px' :append-to-body='true'>
            <el-form :model="blackForm" :rules="rules" ref="blackForm" label-width="100px" size='medium'>
                <el-form-item label="车牌号" prop="bk_plateno">
                     <el-input v-model="blackForm.bk_plateno"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>

</template>

<script>
import util from '../../util/util'

export default {
  data: function () {
    return {
      dialogVisible: false,

      blackForm: {
        bk_id: 0,
        bk_plateno: ''
      },
      rules: {
        bk_plateno: [{ required: true, message: '请输入车牌号', trigger: 'blur' }]
      },
      isFinish: true
    }
  },
  created () {},
  mounted () {
    if(this.$store.state.BlackDataList.length===0){
        this.$store.dispatch('getBlackListDataList')
    }
  },
  methods: {
    AddClick () {
      if(util.CheckUserAuth('2-3-1')){
        this.dialogVisible = true
        this.blackForm.bk_plateno = ''
        this.bk_id = 0
      }
    },
    submit () {
      let arr= this.$store.state.BlackDataList.filter(a=>a.bk_plateno===this.blackForm.bk_plateno.toUpperCase()) 
      if(arr.length>0){
        this.$message.error('车牌号已存在')
        return
      }


      this.$refs.blackForm.validate((valid) => {
        if (valid) {
          util.Post('blacklist/insert', this.blackForm).then(res => {
            if (res.rpStatus === 10000) {
              this.$store.dispatch('getBlackListDataList') 
              this.dialogVisible = false
            } else { this.$message.error(res.rpMsg) }
          })
        }
      })
    },
    Del (row) {
      if(util.CheckUserAuth('2-3-2')){
        this.$confirm('确定删除黑名单“' + row.bk_plateno + '”？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var list = []
          list.push(row)
          util.Post('blacklist/del', list).then(res => {
            if (res.rpStatus === 10000) {
              this.$store.dispatch('getBlackListDataList') 
            } else { this.$message.error(res.rpStatus) }
          })
        }).catch(e => e)
      }
    },
    DelList () {
      if(util.CheckUserAuth('2-3-3')){
        var list = this.$refs.bkTable.selection
        if (list.length > 0) {
          this.$confirm('确定删除勾选的黑名单？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            util.Post('blacklist/del', list).then(res => {
              if (res.rpStatus === 10000) {
                this.$store.dispatch('getBlackListDataList') 
              }
            })
          }).catch(e => e)
        }
      }
    },
    AllDown () {
      if (this.isFinish) {
        this.isFinish=false
        util.Send('temp_blacklist').then(res=>{
          this.isFinish=true
        })
      }
    }
  }
}
</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
}
.tool{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}


.el-table{
    margin-top: 10px;
}
::v-deep .el-dialog{
  --el-bg-color:#000741 !important;
}
::v-deep .el-dialog__header{
  --el-text-color-primary:#1effff;
  --el-text-color-regular:#fff;
  padding: 0px !important;
  background-color: #309CE2;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-dialog__title{
    color: white;
}

::v-deep .el-dialog__headerbtn .el-dialog__close{
    color: white;
} 
</style>
